/* Footer.css */

.footer {
    background-color: #333;
    color: #fff;
    padding: 40px 0;
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
  }
  
  .footer-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-logo {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 20px;
  }
  
  .footer-logo img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  
  .footer-logo span {
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
  }
  
  .footer-links {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .footer-links a {
    color: #fff;
    text-decoration: none;
    font-size: 1rem;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  
  .footer-social {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .footer-social a {
    color: #fff;
    font-size: 1.5rem;
  }
  
  .footer-social a:hover {
    color: #1da1f2;
  }
  
  .footer-copyright {
    font-size: 0.9rem;
    color: #ccc;
    margin-top: 20px;
  }
  