@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');

* {
  box-sizing: border-box;
}

body {
  font-family: 'Muli', sans-serif;
  background-color: #444141;
}

h1 {
  margin: 50px 0 30px;
  text-align: center;
}

.cases-container {
  max-width: 1000px;
  margin: 0 auto;
}

.case-card {
  background-color: #F5F5F5;
  border: 1px solid #9fa4a8;
  border-radius: 10px;
  margin: 20px 0;
  padding: 30px;
  position: relative;
  overflow: hidden;
  transition: 0.3s ease;
}

.case-card.active {
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
}

.case-card.active::before,
.case-card.active::after {
  content: '\f075';
  font-family: 'Font Awesome 5 Free';
  color: #2ecc71;
  font-size: 7rem;
  position: absolute;
  opacity: 0.2;
  top: 20px;
  left: 20px;
  z-index: 0;
}

.case-card.active::before {
  color: #3498db;
  top: -10px;
  left: -30px;
  transform: rotateY(180deg);
}

.case-title {
  margin: 0 35px 0 0;
}

.case-description {
  display: none;
  margin: 30px 0 0;
}

.case-card.active .case-description {
  display: block;
}

.case-toggle {
  background-color: transparent;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 0;
  position: absolute;
  top: 30px;
  right: 30px;
  height: 30px;
  width: 30px;
}

.case-toggle:focus {
  outline: 0;
}

.case-toggle .fa-times {
  display: none;
}

.case-card.active .case-toggle .fa-times {
  color: #fff;
  display: block;
}

.case-card.active .case-toggle .fa-chevron-down {
  display: none;
}

.case-card.active .case-toggle {
  background-color: #9fa4a8;
}
