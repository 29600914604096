@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');


.form-container {
  max-width: 1000px;
  margin: 30px auto;
  background-color: whitesmoke;
  padding: 30px;
  border-left: 5px solid #00d1b2;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%);
}

.heading {
  display: block;
  color: white;
  font-size: 1.5rem;
  font-weight: 800;
  margin-bottom: 20px;
}

.input, .textarea {
  color: #00d1b2;
  width: 100%;
  background-color: #002733;
  border: none;
  outline: none;
  padding: 10px;
  margin-bottom: 20px;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  border-left: 1px solid transparent;
}

.input:focus, .textarea:focus {
  border-left: 5px solid rgb(0, 189, 189);
}

.textarea {
  background-color: #013747;
  color: #00d1b2;
  resize: none;
  max-height: 150px;
}

.button-container {
  display: flex;
  gap: 10px;
}

.send-button {
  flex-basis: 70%;
  background: #00d1b2;
  padding: 10px;
  color: #001925;
  text-align: center;
  font-weight: bold;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
}

.send-button:hover {
  background: transparent;
  border: 1px solid #00d1b2;
  color: #00d1b2;
}

.reset-button-container {
  filter: drop-shadow(1px 1px 0px #00d1b2);
  flex-basis: 30%;
}

.reset-button {
  position: relative;
  text-align: center;
  padding: 10px;
  color: #00d1b2;
  font-weight: bold;
  background: #001925;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%);
  transition: all 0.2s ease-in-out;
}

.reset-button:hover {
  background: #00d1b2;
  color: #001925;
}
