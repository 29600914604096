.about-us {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto; /* Ajuste para altura automática em dispositivos móveis */
  min-height: 100vh; /* Garante que ocupe a altura total da tela */
  background-color: #f4f4f4;
  padding: 20px; /* Adiciona padding para melhor espaçamento */
}

.stats {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.stat {
  background: linear-gradient(135deg, white, #00d1b2);
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.35);
  padding: 20px;
  text-align: center;
  width: 250px;
  transition: transform 0.6s ease-in-out, box-shadow 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  color: black;
}

.stat::before, .stat::after {
  content: '';
  position: absolute;
  width: 200%;
  height: 200%;
  background: rgba(255, 255, 255, 0.1);
  top: -100%;
  left: -100%;
  transform: rotate(45deg);
  transition: transform 0.5s ease-in-out;
}

.stat:hover::before, .stat:hover::after {
  transform: rotate(90deg);
}

.stat:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.25);
}

.stat i {
  font-size: 3em;
  margin-bottom: 10px;
}

.stat h3 {
  font-size: 2.2em;
  margin: 10px 0;
}

.stat p {
  font-size: 1.2em;
}

/* Regras de mídia para responsividade */
@media (max-width: 1200px) {
  .stat {
      width: 200px;
      padding: 20px;
  }

  .stat i {
      font-size: 2.5em;
  }

  .stat h3 {
      font-size: 1.8em;
  }

  .stat p {
      font-size: 1em;
  }
}

@media (max-width: 900px) {
  .stats {
      flex-direction: column;
      align-items: center;
  }

  .stat {
      width: 90%;
      max-width: 300px;
  }
}

@media (max-width: 600px) {
  .stat {
      width: 100%;
      padding: 15px;
  }

  .stat i {
      font-size: 2em;
  }

  .stat h3 {
      font-size: 1.5em;
  }

  .stat p {
      font-size: 0.9em;
  }
}
