.logo {
  display: flex;
  align-items: center;
}

.container {
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .ring {
  position: relative;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 4px solid transparent;
  margin: -30px;
  border-top: 4px solid aqua;
  animation: animate 4s linear infinite;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container .ring::before {
  content: '';
  position: absolute;
  width: 15px;
  top: 12px;
  border-radius: 50%;
  right: 12px;
  height: 15px;
  background-color: aqua;
  box-shadow: 0 0 0 5px #24ecff33, 0 0 0 10px #24ecff33, 0 0 0 15px #24ecff33;
}

.container .ring:nth-child(2) {
  animation: animate2 4s linear infinite;
  border-top: 4px solid transparent;
  border-left: 4px solid #93ff2d;
  animation-delay: -1s;
}


.container .ring:nth-child(2)::before {
  content: '';
  position: absolute;
  width: 15px;
  bottom: 12px;
  border-radius: 50%;
  left: 12px;
  height: 15px;
  background-color: rgb(4, 255, 0);
  box-shadow: 0 0 0 5px #93ff2d22, 0 0 0 10px #93ff2d22, 0 0 0 15px #93ff2d22;
  top: initial;
}

@keyframes animate2 {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
