@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('@fortawesome/fontawesome-free/css/all.min.css');

body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.App-header {
  height: 100vh;
  background-image: url('../src/assets/images/banner-consultoria.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  color: white;
}

.App-header__info {
  max-width: 760px;
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 16px 0 60px;
}

.App-header__info h1 {
  text-shadow: rgb(0, 247, 255) 1px 0 10px;
  text-align: left;
  z-index: 1;
}

.App-header__btn {
  display: flex;
  justify-content: left;
  margin: -110px 26px 0 26px;
  width: 100%;
}

button.custom-button {
  margin-top: 20px;
  padding: 15px 40px;
  background-color: #00c3d1;
  color: white;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

button.custom-button:hover {
  background-color: #019982;
  transform: translateY(-1px); 
}

button.custom-button:active {
  background-color: #019982;
}

main {
  padding: 0px;
}

section {
  text-align: center;
}

.subtitle_h2 {
  color: #333;
  font-size: 36px;
  font-weight: 700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); 
  margin-top: 0; 
  margin-bottom: 20px;
  text-transform: uppercase;
}

.subtitle_p {
  font-size: 1.2em;
  color: #f1f1f1; 
  text-align: center;
  padding: 20px; 
  line-height: 1.6; 
  max-width: 1024px; 
  margin: 24px auto;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); 
  font-family: 'Montserrat', sans-serif;
}

.contact {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}



@keyframes slide-in {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: 768px) {

  .App{width: 100%;}

  h1 {
    font-size: 2.5rem;
  }

  .subtitle{padding: 4px 16px;max-width: 360px;}

}

