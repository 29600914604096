/* Navbar Styles */
.navbar {
  position: fixed;
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 20px;
  background: rgba(0, 0, 0, 0.3);
  transition: background 0.3s;
  z-index: 1000;

}

.navbar.scrolled {
  background: rgba(0, 0, 0, 0.95);
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.navbar-logo .company-name {
  font-size: 1.5rem;
  color: white;
  margin-left: 30px;
}

.navbar-logo .company-name .solutions {
  font-size: 1rem;
  color: #00d1b2;
}

.navbar-menu {
  display: flex;
  gap: 30px;
  margin: 45px auto;
  padding: 16px 0px;
}

.navbar-menu a {
  color: white;
  text-decoration: none;
  font-size: 1.4rem;
  transition: color 0.3s;
}

.navbar-menu a:hover {
  color: #00d1b2;
  border-bottom: 1px solid #00d1b2;
  transition: 700ms;
}

.menu-toggle {
  display: none;
  cursor: pointer;
}

.hamburger {
  width: 35px;
  height: 5px;
  background: white;
  border-radius: 5px;
  position: relative;
  transition: all 0.3s;
}

.hamburger::before,
.hamburger::after {
  content: '';
  width: 25px;
  height: 3px;
  background: white;
  border-radius: 5px;
  position: absolute;
  transition: all 0.3s;
}

.hamburger::before {
  transform: translateY(-8px);
}

.hamburger::after {
  transform: translateY(8px);
}

.hamburger.open {
  transform: rotate(45deg);
}

.hamburger.open::before {
  transform: rotate(90deg) translateX(8px);
}

.hamburger.open::after {
  transform: rotate(90deg) translateX(-8px);
}

@media (max-width: 768px) {
  .navbar-menu {
    display: none;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.75) !important;
    position: absolute;
    top: 70px;
    right: 0;
    width: 100%;
    text-align: center;
  }

  .navbar-menu.open {
    display: flex;
    padding: 16px auto !important;
  }

  .menu-toggle {
    display: block;
  }
}
